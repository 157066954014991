#rs-builder {
  // Features Overview
  .block.features-ov {
    .features-carousel {
      height: fit-content;
    }
    .swiper-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      padding: 5px;
    }
    .swiper-slide {
      opacity: 1 !important;
      width: 250px;
      height: auto;
    }
    .swiper-button-prev, .swiper-button-next {
      display: none;
    }
  }
  // Properties
  .block.properties {
    .properties-list {
      .property-card {
        position: relative;
        a {
          display: block !important;
          opacity: 1;
          height: 40px;
          &::after {
            content: 'Link to Property';
            color: #fff;
            background-color: #f00;
            padding: 5px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  // Interactive Map
  .block.interactive-map {
    .tab-link a {
      pointer-events: all !important;
      text-decoration: none !important;
    }
    .site-map-link {
      background-color: rgb(180, 180, 180);
      height: 40px;
      width: 200px;
      margin-bottom: 10px;
      display: block;
      position: relative;
      &::after {
        content: 'Link to Site Map';
        color: #000;
        padding: 5px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }
  }
  // Floorplans Intro
  .block.floorplans-intro {
    .tab-links {
      a {
        pointer-events: all !important;
        text-decoration: none !important;
      }
    }
  }
  // Floorplans
  .block.floorplans {
    z-index: 0;
  }

  // Gallery
  .block.gallery {
    .gallery-block {
      .background-image {
        background-image: url('https://placehold.co/200x100?text=BG+Image');
        height: 100px;
        width: 200px;
        &::after {
          display: none;
        }
      }
    }
  }
  .block.gallery-menu {
    z-index: 1 !important;
    .gallery-nav {
      &::before {
        content: 'Gallery Navigation Block -- Not Editable';
        display: block;
        background-color: rgb(78, 78, 78);
        color: #fff;
        padding: 5px;
        text-align: center;
      }
    }
  }
  // Contact
  .block.contact {
    .outer {
      z-index: 0;
    }
  }
}