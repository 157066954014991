.block.hero {
  position: relative;
  height: calc(100vh);
  height: calc(100dvh);
  min-height: 500px;
  width: 100%;
  background-color: #767676;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .logo-container {
    position: absolute;
    top: 0;
    max-width: 150px;
    max-height: 60px;
    margin: 20px 0 0 20px;
    z-index: 2;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      filter: drop-shadow(0 2px 2px #000);
    }
  }
}