.block.cta {
  padding: 40px 20px;
  background-color: @page-bg;

  .inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .title {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 20px;
    * {
      line-height: 1.125;
    }
  }

  .content {
    margin-bottom: 20px;
    p {
      margin-bottom: 10px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    .button {
      margin: auto;
      width: 100%;
      max-width: 300px;
      &:hover, &:focus {
        opacity: 0.6;
        background-color: @button-bg;
        color: @button-text;
      }
      &.ghost {
        color: @button-text-hover;
        background-color: transparent;
        &:hover, &:focus {
          opacity: 0.6;
          background-color: @button-bg;
          color: @button-text;
        }
      }
    }
  }

  .right {
    width: 100%;
    .image-wrapper {
      width: 100%;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 300px;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .vp(500px, {
    .buttons {
      .button {
        margin: 0;
        width: fit-content;
      }
    }
  });

  .tablet({
    .inner {
      flex-direction: row;
    }
    .left, .right {
      flex: 1;
    }
    .right {
      display: flex;
      align-items: center;
      .image-wrapper {
        img {
          max-height: 500px;
        }
      }
    }

  })
}