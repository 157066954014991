.block.contact {
  position: relative;
  background-color: @primary-bg;
  background-repeat: repeat;

  .outer {
    padding: 60px 20px;
    background-color: @page-bg;
    position: relative;
  }
  header {
    .title {
      margin-bottom: 20px;
    }
  }
  article.inner {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: @text;
      opacity: 0.2;
      pointer-events: none;
      z-index: 0;
    }
  }

  .property-details, .form-container {
    width: 100%;
  }

  .contact-body {
    padding: 40px 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 45px;
      z-index: 1;
      position: relative;
    .property-details {
      padding: 30px;
      position: relative;
      * {
        font-size: 1.8rem;
      }
      .name {
        font-weight: 500;
      }
      .title {
        margin: 10px 0;
      }
      .hours p, .email a, .phone a, address a {
        font-weight: 300;
      }
      .email {
        a {
          transition: all 0s;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: @secondary-bg;
        filter: hue-rotate(327deg) brightness(1.1) contrast(0.7);
        pointer-events: none;
        z-index: -1;
      }
    }
    .form-container {
      background-color: @primary-bg;
      padding: 30px;
      .form-intro {
        .heading {
          * {
            .fontSize(2.0,2.0,2.0);
            color: @text;
          }
        }
        .message {
          margin: 20px 0;
        }
      }

      // Make labels appear inside the input fields
      .form-group {
        position: relative;
        padding-top: 2.5rem;
        label {
          position: absolute;
          top: 3.5rem;
          left: 30px;
          transition: all 0.3s ease;
          pointer-events: none;
          opacity: 0.5;
        }
        &:focus-within label, &:has(.has-content) label, &:has(input:-webkit-autofill)  label {
          top: 0;
          left: 0;
          opacity: 1;
        }
      }
      button, .g-recaptcha, input[type="submit"] {
        padding: 15px !important;
        margin-top: 10px;
      }

    }
  }

  .tablet({
    .contact-body {
      padding: 40px;
      .property-details {
        width: calc(33.3333% - (45px/2));
      }
      .form-container {
        width: calc(66.6667% - (45px/2));
      }
    }
  });

  .desktop({
    .contact-body {
      padding: 100px;
      .form-container {
        padding: 60px;
      }
    }
  });
}