.block.amenities {
  position: relative;
  background-color: @page-bg;
  padding: 80px 20px 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @page-bg;
    opacity: 0.4;
    z-index: 0;
  }
  .inner {
    z-index: 1;
    position: relative;
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 80px;
    .icon {
      width: 15rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 85px;
        height: auto;
        object-fit: contain;
      }
      .content {
        margin-top: 10px;
        .fontSize(1.8, 2.4, 2.4);
        text-align: center;
        text-transform: capitalize;
      }
    }
  }

  .lists {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .title {
      margin-bottom: 30px;
      * {
        color: @text;
        font-family: @main;
        font-size: 1.8rem;
        text-transform: uppercase;
      }
    }
  }

  .list {
    background-color: @primary-bg;
    padding: 40px 50px;
    ul {
      column-count: 1;
      li {
        margin-left: 30px;
      }
    }
  }

  .disclaimer {
    text-align: end;
    margin-top: 5px;
  }

  .tablet({
    .icons {
      gap: 40px;
    }
    .lists {
      ul {
        column-count: 2;
      }
    }
  });

  .desktop({
    .icons {
      gap: 80px;
    }
    .lists {
      flex-direction: row;
      ul {
        column-count: 1;
      }
      .list {
        width: calc(50% - 10px);
      }
    }
  });
}