.block.interactive-map {
  position: relative;
  background-color: @page-bg;
  padding: 60px 0;
  .inner {
    padding: 0 20px;
  }
  .tab-links {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-width: 300px;
    transform: translateY(calc(-100% - 60px));
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: end;
    .tab-link {
      display: flex;
      align-self: stretch;
      width: 100%;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        background-color: @primary-bg;
        color: @text;
        cursor: pointer;
        transition: all 0.3s;
        text-transform: uppercase;
        letter-spacing: 2px;
        width: 100%;
        height: 100%;
        text-align: center;
        border: 1px solid @primary-bg;
      }
      &:not(.active):hover, &:not(.active):focus-within {
        a {
          text-decoration: none;
          background-color: @heading-link;
          color: @heading-alt;
          border: 1px solid @heading-link;
        }
      }
      &.active {
        a {
          pointer-events: none;
          background-color: @page-bg;
          border: 1px solid @page-bg;
        }
      }
    }
  }
  .title {
    margin-bottom: 20px;
  }
  .content {
    margin-bottom: 30px;
    p {
      margin-bottom: 10px;
    }
  }
  .map-wrapper {
    width: 100%;
    height: 80vh;
    height: 80dvh;
    min-height: 300px;
  }

  .tablet({
    .tab-links {
      width: fit-content;
      left: 20px;
      .tab-link {
        align-self: unset;
        width: fit-content;
        a {
          word-break: keep-all;
          display: block;
          padding: 20px;
          letter-spacing: 2px;
          width: fit-content;
          min-width: 200px;
        }
      }
    }
    .map-wrapper {
      width: 100%;
      height: 600px;
    }
  });

  .vp(1170px, {
    .tab-links {
      left: 0;
    }
  });
}