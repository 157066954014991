.block.amenities-ov {
  background-color: @page-bg;
  padding: 40px 0;
  .inner {
    padding: 0 20px;
  }
  .heading {
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    .title, .subtitle {
      * {
        color: @text;
      }
    }
  }
  .images-container {
    display: flex;
    flex-wrap: nowrap;
    height: 150px;
    gap: 5px;
    margin-bottom: 30px;
    .image-wrapper {
      width: 33.3333%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .amenities-list {
    background-color: @primary-bg;
    padding: 40px 50px 20px;
    ul {
      list-style: none;
      column-count: 1;
      li {
        padding-bottom: 15px;
      }
    }
  }

  .tablet({
    .heading {
      margin-bottom: 30px;
    }
    .amenities-list {
      ul {
        column-count: 2;
      }
    }
    .images-container {
      display: flex;
      flex-wrap: nowrap;
      height: 375px;
      gap: 10px;
      margin-bottom: 50px;
      .image-wrapper {
        width: 33.3333%;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  });
}