html {
	font-size: 62.5%;
	min-width: 300px;
	background-color: @page-bg;
}

*, *::before, *::after{
	box-sizing: border-box;
}

body{
	font-size: 1.6rem;
	line-height: 1.75;
	color: @text;
	font-family: @main;
}

img{
	display: block;
}

#mainwrap{
	// overflow: hidden;
}

.inner{
	position: relative;
	margin: auto;
	max-width: 1338px;
	width: 100%;
}

#cookie-box {
	z-index: 999999 !important;
}

#navwrap {
	top: 0;
  z-index: 11;
}

#standard-neighborhood-map-nav a.active, #standard-neighborhood-map-nav a:hover, #standard-neighborhood-map-nav a:focus-visible {
	background: color-mix(in srgb, #fff 45%, @button-bg) !important;
}

.block.iframe-embed {
	iframe {
		max-width: 100%;
	}
}