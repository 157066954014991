form{
	margin: auto;
	max-width: 780px;

	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	input[type="phone"],
	select,
	textarea {
		box-sizing: border-box;
		width: 100%;
		height: 5rem;
		padding: 8px 10px;
		border-radius: 3px;
		border:0;
		text-indent: 20px;
		background-color: @page-bg;
		border: 1px solid @page-bg;
		font-family: @main;
		&::placeholder {
			display: none;
		}

		&:focus, &:active {
		}

		&[disabled] {
			&:focus, &:active {
			}
		}
	}

	select {
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		padding-right: 25px;
		border-radius: none;
	}
	select::-ms-expand {
    display: none;
	}

	.select-custom {
		position: relative;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid @text;
			pointer-events: none;
		}
	}

	textarea {
		box-sizing: border-box;
		min-height: 200px;
		width: 100%;
		padding: 15px 20px;
		border:0;
		text-indent: 0px;
		border-radius: 3px;
		background-color: @page-bg;
		border: 1px solid @page-bg;
		font-family: @main;
		box-sizing: border-box;
		resize: vertical;
		max-height: 500px;
		&::placeholder {
			display: none;
		}
	}

	input[type="checkbox"] {

	}

	input[type="radio"] {

	}

	input[type="submit"], input[type="button"], input[type="reset"], button {
		.button;
		width: 100%;
		padding: 20px;
	}

	input[type="color"] {

	}

	input[type="file"] {

	}

	input[type="range"] {

	}

	input[type="password"] {
		letter-spacing: 0.3em;
	}

	::-webkit-input-placeholder {
		//color: #fff;
	}

	::-moz-placeholder {
		//color: #fff;
	}

	:-ms-input-placeholder {
		//color: #fff;
	}

	:-moz-placeholder {
		//color: #fff;
	}

	label{
		// font-weight: bold;
	}

	.field-wrapper{
		padding-bottom: 25px;
	}
}




@media screen and (min-width: 768px){
	.fields, reform{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
	}

	.iwrap, .form-group, .field-wrap {
		width: 100%;
		padding-bottom: 12px;

		&.quarter{
			width: 23%;
		}

		&.half{
			width: 48%;
		}

		&.third{
			width: 73%;
		}

		&.one-third{
			width: 31%;
		}

		&.two-thirds{
			width: 65%;
		}

		&.textarea, &.submit, &.full{
			width: 100%;
		}

		&.submit{
			padding-left: 0;
			margin-left: 0;
		}
	}
}