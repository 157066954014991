.block.footer {
  background-color: @secondary-bg;
  padding: 40px 20px;
  color: @heading;
  .top {
    padding: 20px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    .main-logo, .property, .badge {
      margin-bottom: 25px;
    }
    .main-logo {
      width: 175px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    .properties {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 30px;
      text-align: center;
      .property {
        width: fit-content;
        min-width: 200px;
        max-width: 250px;
        .title, .street, .city, .state, .hours, .phone {
          text-transform: uppercase;
          .fontSize(1.6,1.6,1.6);
        }
        .title {
          * {
            font-size: 1.8rem;
            font-weight: 700;
          }
        }
        .address {
          margin-bottom: 20px;
        }
      }
    }
    .buttons {
      margin-bottom: 25px;
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        li {
          width: fit-content;
        }
      }
      .button {
        background-color: @button-bg-hover;
        color: @button-text-hover;
        &:hover, &:focus {
          opacity: 0.8;
        }
      }
    }
    .badges {
      display: flex;
      flex-direction: column;
      align-items: center;
      .badge {
        width: 125px;
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
      .partners {
        img {
          filter: contrast(0) brightness(0) invert(1);
        }
      }
    }
  }
  .bottom {
    text-align: center;
    border-top: 1px solid @heading-alt;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    .links, .copyright, .icons, .resite {
      margin-bottom: 5px;
    }
    .links {
      nav {
        ul {
          display: flex;
          flex-direction: row;
          justify-content: center;
          li:not(:last-child) {
            margin-right: 5px;
            &::after {
              content: '|';
              margin: 0 5px;
            }
          }
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      .icons {
        img {
          height: 30px;
          width: auto;
          margin: auto;
          filter: contrast(0) brightness(0) invert(1);
        }
      }
    }
  }

  .tablet({
    .top {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      .properties {
        width: fit-content;
        text-align: start;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: start;
        align-items: flex-start;
        padding: 0 50px;
        gap: 40px;
      }
      .buttons {
        margin-left: auto;
        margin-right: 25px;
        ul {
          align-items: end;
        }
      }
      .badges {
        align-self: end;
        align-items: end;
      }
      .right {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        min-width: fit-content;
        justify-content: center;
      }
    }
    .bottom {
      text-align: left;
      flex-direction: row;
      justify-content: space-between;
      .links {
        width: 50%;
        nav {
          ul {
            justify-content: flex-start;
            flex-wrap: wrap;
          }
        }
      }
      .right {
        width: 50%;
        flex-direction: row;
        justify-content: flex-end;
        word-break: keep-all;
        flex-wrap: wrap;
        gap: 10px;
        .copyright {
          text-align: end;
        }
        .icons {
          img {
            margin: 0;
          }
        }
      }
    }
  });

  .desktop({
    padding: 40px 100px;
    .top {
      .right {
        width: calc(100% - 175px);
      }
    }
  });
}