.block.topbar {
  position: relative;
  background-color: transparent;
  .skip-link {
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
    &:focus-within {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      height: auto;
      overflow: visible;
      z-index: 9999;
      border-radius: 5px;
      a {
        padding: 20px 40px;
        background-color: @text-alt;
        color: @text;
        text-transform: uppercase;
        font-size: 2.0rem;
        font-weight: 700;
        &:hover, &:focus {
          text-decoration: none !important;
        }
      }
    }
  }

    header {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
      background-color: transparent;
    }
    .quick-links {
      display: flex;
      align-items: center;
      flex-direction: row;
      border-radius: 0 0 0 20px;
      padding: 16px 80px 16px 20px;
      letter-spacing: 2px;
      position: relative;
      min-height: 65px;
      .phone {
        display: none;
        margin-right: 35px;
        font-size: 2.2rem;
        line-height: 1.2;
        background-color: rgba(0, 0, 0, 0);
        transition: all 0.3s ease-in-out;
      }
      .phone-mobile, .map-marker-link {
        visibility: visible;
        opacity: 1;
        transition: all 0.3s ease-in-out;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover, &:focus-visible {
            opacity: 0.6;
          }
        }
        margin-right: 0;
        padding: 0 10px;
        svg {
          height: 20px;
          width: 20px;
          * {
            fill: @text-alt;
          }
        }
      }
      .links {
        display: flex;
        align-items: center;
        display: none;
        visibility: visible;
        opacity: 1;
        transition: all 0.3s ease-in-out;

        ul {
          display: flex;
          flex-direction: row;
          gap: 35px;
          li {
            display: flex;
            flex-direction: column;
            a {
              text-transform: uppercase;
              background-color: rgba(0, 0, 0, 0);
            }
          }
        }
      }

      .phone a, .links li {
          display: block;
          &::after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background-color: currentColor;
            transition: width 0.3s;
          }
          &:hover, &:focus, &:focus-within {
            text-decoration: none;
            a {
              text-decoration: none;
            }
            &::after {
              width: 100%;
            }
          }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: @secondary-bg;
        opacity: 0.7;
        border-radius: inherit;
        z-index: -1;
        transition: all 0.3s ease-in-out;
      }
    }

    .menu-button {
      position: absolute;
      right: 10px;
      border-radius: 4px;
      button {
        background-color: transparent;
        border: none;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 6px 8px 8px;
        box-shadow: none;
        span {
          display: block;
          width: 45px;
          height: 4px;
          background-color: @text-alt;
          transition: all 0.3s;
        }
        &:hover, &:focus-visible {
          span:first-child {
            width: 60px;
          }
          span:nth-child(2) {
            width: 50px;
          }
        }
      }
    }

    .menu-overlay {
      visibility: hidden;
      opacity: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      background-color: @page-bg;
      z-index: 999;
      transition: all 0.3s;

      &.show {
        visibility: visible;
        opacity: 1;
      }
      .background-pattern {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.2;
        img {
          width: 100%;
          height: 100%;

        }
      }
      .logo {
        position: absolute;
        top: 10px;
        left: 30px;
        width: 80px;
        height: 80px;
        z-index: 1;
        filter: drop-shadow(0 2px 2px #000);
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .close {
        position: absolute;
        top: 40px;
        right: 60px;
        background-color: transparent;
        border: none;
        padding: 0;
        height: 40px;
        width: 40px;
        z-index: 1;
        box-shadow: none;
        span {
          display: block;
          width: 100%;
          height: 5px;
          background-color: @secondary-bg;
          transition: all 0.3s;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          &:first-child {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
          &:last-child {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
        &:hover, &:focus {
          span {
            background-color: @text;
          }
        }
      }
      .left, .right {
        height: 100%;
      }
      .left {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .right {
        width: 100%;
        position: relative;
        padding: 100px 50px 40px;
        overflow-y: auto;
        transition: all 0.3s;
        nav {
          margin-bottom: 45px;
          li {
            margin-bottom: 5px;
          }
          a {
            .fontSize(2.2, 2.6, 2.8);
            font-weight: 700;
            letter-spacing: 2px;
            text-transform: uppercase;
            position: relative;
            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              display: block;
              width: 0;
              height: 2px;
              background-color: currentColor;
              transition: width 0.3s;
            }
            &.current {
              &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -30px;
                display: block;
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid @text-link-hover;
                transition: all 0.3s;
              }
            }
            &:hover, &:focus {
              text-decoration: none;
              opacity: 1;
              color: @text;
              &:before {
                width: 100%;
              }
              &.current {
                &:after {
                  border-left: 10px solid @text;
                }
              }
            }
          }
        }

        .buttons {
          margin-bottom: 45px;
          list-style: none;
          ul {
            list-style: none;
          }
          li {
            .button {
              border-radius: 0;
              min-width: 175px;
              text-transform: uppercase;
            }
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }

        .address {
          * {
            color: @heading;
            text-transform: uppercase;
          }
          margin-bottom: 20px;
          address {
            width: fit-content;
          }
        }

        .phone {
          margin-bottom: 30px;
          a {
            .fontSize(1.8, 2.0, 2.0);
            font-weight: 500;
            letter-spacing: 2px;
            display: inline-block;
            &::after {
              content: '';
              display: block;
              width: 0;
              height: 2px;
              background-color: currentColor;
              transition: width 0.3s;
            }
            &:hover, &:focus {
              color: @text;
              text-decoration: none;
              &::after {
                width: 100%;
              }
            }
          }
        }

        .socials {
          display: flex;
          gap: 15px;
          .social {
            height: 25px;
            width: 25px;
            transition: all 0.3s;
            path, circle, rect, line {
              fill: @secondary-bg;
            }
            &:has(a[href=""]) {
              display: none;
            }
            a:hover, a:focus {
              path, circle, rect, line {
                fill: @text;
              }
            }
          }

        }
      }
    }

    .scroll-hide {
      pointer-events: none;
      .phone, .links, .phone-mobile, .map-marker-link {
        visibility: hidden;
        opacity: 0;
      }
      .quick-links::after {
        background-color: transparent;
      }
      .menu-button {
        pointer-events: all;
        .menu-bar {
          background-color: @text;
          box-shadow: 1px 1px 2px #fff;
        }
      }
    }

  .tablet({
    .menu-overlay {
      .left, .right, .background-pattern {
        width: 50%;
      }
      .logo {
        width: 120px;
        height: 120px;
        top: 40px;
        left: 60px;
      }
    }
  });

  .desktop({
    .quick-links {
      padding: 16px 90px 16px 50px;
      .phone, .links {
        display: block;
      }
      .phone-mobile, .map-marker-link {
        display: none;
      }
    }
    .menu-overlay {
      .right {
        padding: 75px 80px 20px;
        nav {
          a {
            &.current {
              &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -30px;
                display: block;
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-left: 15px solid @secondary-bg;
              }
            }
            &:hover, &:focus {
              &.current {
                &:after {
                  border-left: 15px solid @text;
                }
              }
            }
          }
        }
      }
    }
  });
}


body:has(.block.specials) {
  .block.topbar {
    header {
      top: 60px;
    }
  }
}