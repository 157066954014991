.block.floorplan-single {
  padding: 60px 20px;
  position: relative;
  background-color: @page-bg;
  .return-link {
    margin-bottom: 20px;
    a {
      font-weight: 700;
      color: @text;
    }
  }

  .fp-container {
    .fp-header {
      .specs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        margin: 10px 0 20px;
        .beds, .baths, .sqft {
          display: flex;
          align-items: center;
          gap: 5px;
          svg {
            width: 18px;
            height: 18px;
            path {
              fill: @text;
            }
          }

          span {
            color: @text;
            font-size: 1.4rem;
          }
        }
        .sqft {
          gap: 0;
        }
      }
    }
    .fp-image-container {
      width: 100%;
      margin-bottom: 50px;
      .image-wrapper {
        justify-content: center;
        align-items: center;
        position: relative;
        .image-link {
          display: block;
        }
        img {
          width: 100%;
          height: 98vw;
          max-height: 650px;
          object-fit: contain;
        }
      }
      .image-tabs {
        display: flex;
        flex-direction: row;
        .tab-link {
          a, button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 30px;
            background-color: @primary-bg;
            opacity: 0.5;
            color: @text;
            cursor: pointer;
            transition: all 0.3s;
            text-transform: uppercase;
            letter-spacing: 2px;
            width: 100%;
            height: 100%;
            text-align: center;
            border: none;
            border-radius: 0;
            box-shadow: none;
            margin: 0;
          }
          &:not(.active):hover, &:not(.active):focus-within {
            a, button {
              text-decoration: none;
              background-color: @heading-link;
              color: @heading-alt;
            }
          }
          &.active, &.empty {
            a, button {
              pointer-events: none;
              text-decoration: none;
              background-color: @primary-bg;
              opacity: 1;
            }
          }
        }
      }
      .spinner {
        position: absolute;
        max-width: 60px;
        right: 0;
        bottom: -69px;
        a {
          display: block;
        }
        a[href=""] {
          display: none;
          height: auto;
        }
        svg {
          width: 100%;
          height: auto;
          path {
            fill: @text;
          }
        }
      }
    }
  }

  .fp-features {
    margin: 0 0 25px;
    background-color: @primary-bg;
    padding: 30px 10px;
    * {
      color: @text;
      font-family: @main;
    }
    .title {
      * {
        font-size: 2.2rem;
      }
      margin-bottom: 5px;
    }
    ul {
      column-count: 1;
      column-gap: 10px;
      li {
        margin-left: 20px;
      }
    }
    a {
      .button;
    }
  }

  .available-units {
    background-color: @primary-bg;
    margin: 0 0 25px;
    table {
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      width: 100%;
      table-layout: fixed;
      position: relative;
      caption {
        font-weight: 700;
        font-size: 2.2rem;
        font-family: @main;
        padding: 30px 50px 20px;
      }
      // Hide from view, not reader
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tbody tr {
        display: block;
        position: relative;
        padding: 0 10px;
        &:nth-child(odd) {
          background-color: rgba(255, 255, 255, 0.4);
        }
      }
      td {
        &:first-child {
          padding-top: 10px;
        }
        &:last-child {
          padding: 15px 0;
        }
        display: block;
        text-align: end;
        z-index: 1;
        position: relative;
        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }
      }
      td:not([data-label="Apply"])::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
      }
      td[data-label="Apply"] {
        display: flex;
        justify-content: center;
      }
      // In event apply link is empty, hide data cell
      td {
        a[href="undefined"], a[href=""], a[href="#"], a[href="null"], a[href$="undefined"] {
          display: none;
        }
      }
    }
  }


  .aside {
    padding: 40px 20px;
    width: 100%;
    height: fit-content;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: @secondary-bg;
      filter: hue-rotate(327deg) brightness(1.1) contrast(0.7);
      pointer-events: none;
      z-index: 0;
    }
    .contact-container, .form-container {
      position: relative;
      z-index: 1;
    }

    .contact-container {
      * {
        font-size: 1.8rem;
      }
      .name, .title {
        font-weight: 700;
      }
      .title {
        margin-bottom: 15px;
      }

      .header {
        margin: 10px 0;
        * {
          font-size: 2.2rem;
          font-weight: 700;
        }
      }
      .hours p, .phone a, address * {
        font-weight: 300;
        line-height: 1.5;
      }
      .address, .hours {
        margin-bottom: 30px;
      }
    }

    .form-container {
      .heading {
        * {
          font-size: 1.8rem;
          font-weight: 700;
        }
      }
    }
     // Make labels appear inside the input fields
     .form-group {
      position: relative;
      padding-top: 2.5rem;
      padding-bottom: 0 !important;
      label {
        position: absolute;
        top: 3.5rem;
        left: 30px;
        transition: all 0.3s ease;
        pointer-events: none;
        opacity: 0.5;
      }
      &:focus-within label, &:has(.has-content) label, &:has(input:-webkit-autofill)  label {
        top: 0;
        left: 0;
        opacity: 1;
        color: @text-alt;
      }
      textarea {
        min-height: 125px !important;
      }
    }
    button, .g-recaptcha, input[type="submit"] {
      background: transparent;
      color: @button-text;
      border: 1px solid @button-text;
      box-shadow: none;
      padding: 10px 20px;
      width: fit-content;
      margin: 10px auto 0 0;
      &:hover, &:focus {
        opacity: 1;
        color: @button-bg;
        background-color: @button-text;
      }
    }
  }

  .tablet({
    .fp-container {
      .fp-features {
        padding: 30px 50px;
        ul {
          column-count: 2;
        }
      }
      .available-units {
        table {
          tbody tr {
            padding: 0 50px;
          }
        }
      }
      .fp-image-container {
        .spinner {
          max-width: 60px;
        }
      }
    }
  });

  .desktop({
    .content {
      display: flex;
      gap: 30px;
    }
    .fp-container {
      width: calc(70% - 15px);
      .available-units {
        table {
          table-layout: unset;
          caption {
            text-align: start;
          }
          tr {
            display: table-row;
            background-color: rgba(255, 255, 255, 0.4);
            &:nth-child(even) {
              background-color: rgba(255, 255, 255, 0.4);
            }
            &:nth-child(odd) {
              background-color: transparent;
            }
          }
          thead {
            border: none;
            clip: unset;
            height: unset;
            margin: unset;
            position: relative;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.6);
            th {
              position: relative;
              text-align: start;
              width: 20%;
              padding: 5px;
              &:first-of-type {
                padding-left: 50px;
              }
              &:last-of-type {
                padding-right: 50px;
              }
            }
          }
          td {
            display: table-cell;
            text-align: start;
            border-bottom: none;
            padding: 10px 5px;
            &:first-of-type {
              padding-left: 50px;
            }
            &:last-of-type {
              padding-right: 50px;
            }
            &::before {
              display: none;
            }
          }
        }
      }
    }
    .aside {
      width: calc(30% - 15px);
    }
  });
}



// Spinner styles
#videoSeek {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  outline: none;
  opacity: 0.8;
  transition: opacity 0.2s;
  cursor: pointer;
  margin-top: 20px;
}

#videoSeek:hover, #videoSeek:focus {
  opacity: 1;
}

#videoSeek:focus-visible {
  outline: 1px solid #ebebeb;
}

// Chrome and Safari
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 8px;
  height: 25px;
  background: #ebebeb;
  border-radius: 2px;
  cursor: pointer;
  transform: translateY(-8px);
}

#videoSeek::-webkit-slider-runnable-track {
  height: 8px;
  border-radius: 5px;
  background: rgb(151, 151, 151);
}

// Firefox
input[type="range"]::-moz-range-thumb {
  width: 8px;
  height: 25px;
  background: @primary-bg;
  border-radius: 2px;
  cursor: pointer;
}


#videoSeek::-moz-range-progress {
  background: rgb(151, 151, 151);
  height: 8px;
}