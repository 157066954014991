.block.floorplans {
  position: relative;
  background-color: @page-bg;
  padding: 0 0 60px;
  min-height: 500px;
  .inner {
    padding: 0 20px;
  }


  .fps-container {
    position: relative;
    z-index: 5;
    .fp-filters {
      position: absolute;
      background-color:  @primary-bg;
      padding: 10px 10px;
      top: 0;
      left: -20px;
      transform: translateX(-100%);
      transition: all 0.3s;
      .filter-icon {
        position: absolute;
        top: 0;
        right: -40px;
        background-color: @secondary-bg;
        color: @text-alt;
        padding: 10px;
        border-radius: 0;
        cursor: pointer;
        transition: all 0.3s;
        width: 40px;
        height: 40px;
        box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.4);
        opacity: 1 !important;
        svg {
          width: 20px;
          height: 20px;
          path {
            fill: currentColor;
          }
        }
      }
      form {
        background-color: @primary-bg;
        display: flex;
        flex-direction: column;
        margin: 0;
        gap: 5px;
        .fp-filter {
          width: fit-content;
          min-width: 200px;
          &:not(:last-child) {
            border-bottom: 1px solid @text;
          }
          select {
            padding: 15px 10px;
            background-color: @primary-bg;
            color: @text;
            border: none;
          }
        }
      }
      &.open {
        transform: translateX(0);
        box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.4);
        .filter-icon {
          border-left: 1px solid @text;
        }
      }
    }
  }

  .fp-list {

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .no-results {
        display: none;
        font-size: 2rem;
        margin-top: 20px;
      }
      .price-min {
        display: inline-block;
      }
      .property-card {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: @primary-bg;
        padding: 30px;
        width: 100%;
        margin: 5px;
        &:nth-child(even) {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #ffffff;
            opacity: 0.3;
            z-index: 0;
            pointer-events: none;
          }
        }
        .title, .specs, .image-wrapper, .details, .buttons {
          z-index: 1;
          position: relative;
        }
        .title, .details .price {
          z-index: 1;
          * {
            font-weight: 700;
            font-size: 2.0rem;
            color: @text;
          }
        }
        .title {
          margin-bottom: 20px;
        }
        .specs {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 20px;
          margin-bottom: 20px;
          .beds, .baths, .sqft {
            display: flex;
            align-items: center;
            gap: 5px;
            svg {
              width: 18px;
              height: 18px;
              path {
                fill: @text;
              }
            }

            span {
              color: @text;
              font-size: 1.4rem;
            }
          }
          .sqft {
            gap: 0;
          }
        }
        .image-wrapper {
          // max-height: 350px;
          margin-bottom: 10px;
          a {
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        .details {
          text-align: center;
          margin: 10px 0;
          .availability {
            color: @text;
            font-size: 2.0rem;
            font-weight: 400;
          }
        }
        .buttons {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-top: auto;
        }
      }
    }
  }

  .tablet({
    .fps-container {
      margin-bottom: 10px;
      .fp-filters {
        padding: 0;
        background-color: transparent;
        transform: translateX(0);
        position: relative;
        left: 0;
        form {
          background-color: transparent;
          display: flex;
          flex-direction: row;
          gap: 5px;
          margin: 0;
          box-shadow: none;
          .fp-filter {
            width: fit-content;
            min-width: 150px;
            &:not(:last-child) {
              border-bottom: none;
            }
            select {
              background-color: @primary-bg;

            }
          }
        }
      }
    }
    .fp-list {
      ul {
        .property-card {
          width: calc(50% - 10px);
        }
      }
    }
  });

  .desktop({
    .fp-list {
      ul {
        .property-card {
          width: calc(33.3333% - 10px);
        }
      }
    }
  });

  .vp(1170px, {
    .tab-links {
      left: 0;
    }
  });
}