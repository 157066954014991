.block.specials {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @secondary-bg;
  min-height: 60px;
  .content {
    text-transform: uppercase;
    text-align: center;
    font-family: "Inter", sans-serif;
    letter-spacing: 2px;
    font-size: 1.4rem;
    a:hover, a:focus {
      text-decoration: underline;
    }
  }

  .tablet({
    .content {
      a, p {
        font-size: 1.6rem;
      }
    }
  });

  .desktop({
    height: 60px;
  });

}