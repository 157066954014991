.block.gallery {
  position: relative;
  background-color: @page-bg;

  .gallery-top {
    padding: 60px 20px;
    .title {
      margin-bottom: 20px;
    }
  }

  .gallery-nav {
    background-color: @primary-bg;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0px 2px 4px #6b6b6b;
    padding: 0 75px 0 0;
    ul {
      margin: 0 auto;
      width: 100%;
      max-width: 1170px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: space-around;
    }
    li {
      flex: 1;
      min-width: fit-content;
    }
    li a {
      width: 100%;
      padding: 20px;
      display: block;
      color: @text-link;
      font-weight: 700;
      text-align: center;
    }
    li a:hover, li a:focus-visible {
      background-color: @heading-link;
      color: @page-bg;
      text-decoration: none;
    }
  }

  .gallery-block {
    position: relative;
    padding: 40px 20px;
    z-index: 1;
    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      z-index: 0;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: @page-bg;
        opacity: 0.4;
        z-index: 0;
      }
    }
    header {
      z-index: 1;
      position: relative;
      margin-bottom: 20px;
      text-align: center;
      * {
        color: @text;
      }
      h3 {
        .fontSize(4.0, 4.8, 4.8)
      }
      .eyebrow {
        text-transform: uppercase;
      }
    }
    .images-container {
      z-index: 1;
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 200px;
      gap: 10px;
      .gallery-item {
        overflow: hidden;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000000;
          opacity: 0;
          pointer-events: none;
          transition: ease all .5s;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: ease all .3s;
        }
        &:hover, &:focus-within {
          &::after {
            opacity: 0.5;
          }
        }
        &:has(a:focus-visible) {
          outline: @button-bg auto 3px;
          border: 3px solid transparent;
        }
      }
    }
  }

  &:nth-child(odd) {
    .background-image {
      display: none;
    }
  }

  .tablet({
    .gallery-block {
      padding: 40px;
      .images-container {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 200px;
      }
    }
    .gallery-nav {
      padding: 0 75px;
    }
  });

  .desktop({
    .gallery-block {
      padding: 100px;
      .images-container {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 300px;
      }
    }
  });
}

.slbContentOuter {
  .slbImage {
    padding: 3em 0 5em !important;
  }
  button {
    box-shadow: none !important;
    font-weight: 300 !important;
    font-family: @title !important;
  }
  .slbCloseBtn {
    right: 15px !important;
    top: -10px !important;
    height: unset !important;
    width: unset !important;
  }
  .slbCaption {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* Limits to 2 lines */
    height: 4em;
    font-size: 1.6rem;
    bottom: 15px !important;
    white-space: unset !important;
    text-align: left !important;
    * {
      font-size: 1.6rem;
    }
  }
}