.block.properties {
  background-color: @page-bg;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 20px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @page-bg;
    opacity: 0.4;
    z-index: 0;
  }

  .inner {
    z-index: 1;
    position: relative;
  }

  .heading {
    text-align: center;
    margin-bottom: 20px;
    .title, .subtitle {
      * {
        color: @text;
      }
    }
    .title {
      text-transform: capitalize;
    }
    .subtitle {
      text-transform: uppercase;
    }
  }

  .properties-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    .property-card:only-child {
      .details {
        background-color: @text-link;
      }
    }
    .property-card:not(:only-child) {
      &:nth-child(odd) {
        .details {
          background-color: @text-link;
        }
      }
      &:nth-child(even) {
        .details {
          background-color: @text-link-hover;
        }
      }
    }
    .property-card {
      width: 100%;
      position: relative;
      > a {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 300px;
        z-index: 1;
      }
      .image-wrapper {
        height: 300px;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1.01);
          transition: transform 0.3s;
        }
      }

      .details {
        padding: 15px 20px;
        .name {
          * {
            font-weight: 700;
            .fontSize(2.6, 2.6, 2.6);
            line-height: 1.5;
          }
        }
        address {
          * {
            font-weight: 400;
            .fontSize(1.6, 1.6, 1.6);
            line-height: 1.5;
          }
        }
        // .phone a {
        //   position: relative;
        //   height: unset;
        //   width: unset;
        //   z-index: unset;
        //   opacity: unset;
        // }
      }
    }
  }

  .tablet({
    .heading {
      margin-bottom: 30px;
    }
    .properties-list {

      .property-card:only-child {
        width: 100%;
        .details {
          background: linear-gradient(to right, @text-link 50%, @text-link-hover 50%);
        }
      }
      .property-card:not(:only-child) {
        width: calc(50% - 15px);
      }

      .property-card {
        > a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 450px;
          z-index: 1;
        }
        a[href=""] {
          display: none;
        }
        a:hover+.image-wrapper, a:focus+.image-wrapper {
          img {
            transform: scale(1.04);
          }
        }
        .image-wrapper {
          height: 450px;
        }
      }
    }
  });
}