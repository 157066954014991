article.text-content {
  padding: 40px 20px;
  a {
    color: blue;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    margin-bottom: 10px;
    color: @heading;
  }

  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin: 20px 0;
  }

  ul, ol {
    margin-left: 20px;
  }
}

.block.text-hero {
  position: relative;
  height: 400px;
  width: 100%;
  background-color: #767676;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .logo-container {
    position: absolute;
    top: 0;
    max-width: 150px;
    max-height: 60px;
    margin: 20px 0 0 20px;
    z-index: 2;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      filter: drop-shadow(0 2px 2px #000);
    }
  }

  .tablet({
    height: 500px;
  });
}