.block.amenities-intro {
  position: relative;
  background-color: @page-bg;
  padding: 60px 20px;
  .title {
    margin-bottom: 30px;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .images-container {
      display: grid;
      grid-template-columns: repeat(25, 1fr);
      grid-template-rows: repeat(15, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      margin-bottom: 20px;
      [class^=image-] {
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .image-1 {
        grid-column: 1 / span 12;
        grid-row: 1 / span 10;
        z-index: 1;
      }
      .image-2 {
        z-index: 2;
        grid-column: 8 / span 12;
        grid-row: 6 / span 10;
      }
      .image-3 {
        grid-column: 14 / span 12;
        grid-row: 2 / span 10;
        z-index: 1;
      }
    }
    .text-content {
    }
  }

  .tablet({
    .title {
      margin-bottom: 40px;
    }
    .content {
      display: flex;
      flex-direction: row;
      gap: 60px;
      justify-content: center;
      align-items: center;
      .images-container {
        margin-bottom: 0;
        display: grid;
        grid-template-columns: repeat(19, 1fr);
        grid-template-rows: repeat(8, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 5px;
        width: calc(40% - 30px);
        max-height: unset;
        .image-1 {
          grid-column: 1 / span 12;
          grid-row: 1 / span 4;
          z-index: 1;
        }
        .image-2 {
          z-index: 2;
          grid-column: 8 / span 12;
          grid-row: 3 / span 4;
        }
        .image-3 {
          grid-column: 2 / span 12;
          grid-row: 5 / span 4;
          z-index: 1;
        }
      }
      .text-content {
        width: calc(60% - 30px);
      }
    }
  });
}