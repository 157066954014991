@import '../base/_utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');

// universal
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:focus-visible {
		outline: @button-bg auto 3px;
	}
}

img{
	max-width: 100%;
}


// links
a {
	text-decoration: none;
	transition: ease all .3s;
	outline: none;
	color: @text-link;

	&:hover, &:focus {
		text-decoration: underline;
		color: @text-link-hover;
	}

	&:active {
		text-decoration: none;
	}

	/* mobile tap color */
	&:link {
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}


// headings
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	text-rendering: optimizelegibility;
	margin: 0;
	font-weight: 500;
	line-height: 1.3;
	font-family: @title;
	color: @heading;
}

.rs-editor {
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
		color: #ffffff !important;
	}
}

h1, .h1 {
	.fontSize(4.0,4.8,4.8);

}

h2, .h2 {
	.fontSize(4.0,4.8,4.8);
}

h3, .h3 {
	.fontSize(1.4,1.4,1.4);
}

.eyebrow {
	.fontSize(1.4,1.4,1.4);
	letter-spacing: 2px;
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
		.fontSize(1.4,1.4,1.4);
	}
}

button, .button, a.button{
	position: relative;
	display: inline-block;
	line-height: 24px;
	padding: 8px 22px;
	background: @button-bg;
	border: 2px solid @button-border;
	color: @button-text;
	font-size: 1.6rem;
	font-weight: 500;
	border-radius: 4px;
	transition: ease all .3s;
	text-align: center;
	margin: auto;
	cursor: pointer;
	letter-spacing: 1px;
	text-transform: uppercase;
	box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.2);
	&:hover, &:focus-visible {
		// background: transparent;
		// color: @button-text-hover;
		text-decoration: none;
		box-shadow: none;
		opacity: 0.6;
	}

	&.ghost{
		background: transparent;
		color: @button-text-hover;
		box-shadow: none;
		&:hover, &:focus {
			opacity: 1;
			color: @button-text;
			background-color: @button-bg;
		}
	}

	&.inline{
		display: inline-block;
	}


}

.outline{
	background: none;
	border: 2px solid @button-bg;
	color: #000;
}

p {
	margin: 0;
}

ul, ol {
	margin: 0;
	padding:0;
}

p, ul, ol, dl {
	// + p, + ul, + ol, + dl {
	// 	margin-top: 1.5em;
	// }
}

.text-alt {
	p, li, a, * {
		color: @text-alt;
	}
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
		color: @heading-alt;
	}
}

nav, .nav, nav ul, .nav ul {
	list-style-type: none;
	padding-left: 0;
}

hr {
	border:none;
	background:#eee;
	height:2px;
	width:60px;
	margin:35px auto 0;
}

.alignleft, .alignright {
	margin-bottom: 1em;
}

address {
	font-style: normal;
}

.center {
	text-align: center;
}

.upper {
	text-transform: uppercase;
}

.screen-reader-text {
	position: absolute !important;
	left: -999em;
	color: black !important;
	background: white !important;
}

.html-times {
	font-size: 1.5em;
	vertical-align: sub;
}