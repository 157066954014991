.block.features-ov {
  padding: 40px 20px;
  background-repeat: repeat;
  background-color: @primary-bg;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @text;
    opacity: 0.2;
    pointer-events: none;
    z-index: 0;
  }
  .inner {
    z-index: 1;
  }
  .heading {
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    .title, .subtitle {
      * {
        color: @text;
      }
    }
  }
  .carousel-container {
    overflow: hidden;
    padding: 0 20px 40px;
    position: relative;
    margin-bottom: 30px;
    .swiper-pagination-custom {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: fit-content;

      .pagination-bullet {
        cursor: pointer;
        background: @secondary-bg;
        display: inline-block;
        border-radius: 50%;
        opacity: 0.6;
        width: 15px;
        height: 15px;
        margin: 0 5px;
        box-shadow: 0px 2px 6px black;
        &.active {
          opacity: 1;
        }
      }
    }
    .swiper-button-prev {
      left: 0;
      &::after {
        content: '';
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid @secondary-bg;
      }
      &::before {
        content: '';
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
        border-right: 22px solid #fff;
        position: absolute;
        opacity: 0.5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
    .swiper-button-next {
      right: 0;
      &::after {
        content: '';
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid @secondary-bg;
      }
      &::before {
        content: '';
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
        border-left: 22px solid #fff;
        opacity: 0.5;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }

    .swiper-button-prev, .swiper-button-next {
      transition: all 0.3s;
      &:hover, &:focus {
        opacity: 0.6;
      }
    }
  }
  .features-carousel {
    height: 300px;
    .swiper-wrapper {
      .swiper-slide {
        position: relative;
        height: 100%;
        transition: transform 0.5s;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .swiper-slide-active {
        transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1) translateX(-20%) !important;
        width: 85% !important;
      }
      .swiper-slide-next {
        transform: translate3d(-67.9922px, 0px, -594.932px) rotateX(0deg) rotateY(0deg) scale(1) !important;
      }
      .swiper-slide-prev {
        transform: translate3d(-67.9922px, 0px, -594.932px) rotateX(0deg) rotateY(0deg) scale(1) !important;
      }
    }
  }

  .features-list {
    background-color: @primary-bg;
    padding: 40px 50px 20px;
    ul {
      list-style: none;
      column-count: 1;
      li {
        padding-bottom: 15px;
      }
    }
  }

  .tablet({
    .carousel-container {
      padding: 0 80px 40px;
      .swiper-button-prev {
        left: 20px;
        &::after {
          content: '';
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-right: 20px solid @secondary-bg;
          filter: drop-shadow(0px 10px 9px black);
        }
      }
      .swiper-button-next {
        right: 20px;
        &::after {
          content: '';
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 20px solid @secondary-bg;
          filter: drop-shadow(2px 10px 9px black);
        }
      }

    .features-carousel {
      height: 350px;
      width: 100%;
      transform: translateX(0);
        .swiper-slide-active {
          width: 67% !important;
          transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1) translateX(-15%) !important;
        }
        .swiper-slide-next {
          transform: translate3d(-60px, 0px, -350px) rotateX(0deg) rotateY(0deg) scale(1) !important;
        }
        .swiper-slide-prev {
          transform: translate3d(60px, 0px, -350px) rotateX(0deg) rotateY(0deg) scale(1) !important;
        }
      }
    }
    .features-list {
      ul {
        column-count: 2;
      }
    }
  });

  .desktop({
    .carousel-container {
      .features-carousel {
        height: 400px;
        width: 100%;
        transform: translateX(0);
          .swiper-slide-active {
            width: calc(100% / 2) !important;
            transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
          }
          .swiper-slide-next {
            transform: translate3d(-80px, 0px, -350px) rotateX(0deg) rotateY(0deg) scale(1) !important;
          }
          .swiper-slide-prev {
            transform: translate3d(80px, 0px, -350px) rotateX(0deg) rotateY(0deg) scale(1) !important;
          }
        }
    }
  });
}